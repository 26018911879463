import "../styles/global.scss";
import { useAppRoutes } from "../routes";
import { ConfigProvider } from "antd";
import { Suspense } from "react";

const App = () => {
  const routes = useAppRoutes();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#408AE2",
        },
      }}
    >
      <Suspense fallback={<></>}>{routes}</Suspense>
    </ConfigProvider>
  );
};

export default App;
